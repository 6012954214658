import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Box from "../../../components/box"
import Breadcrumb from "../../../components/breadcrumb"
import Checkbox from "../../../components/checkbox"
import Constrain from "../../../components/constrain"
import Paragraph from "../../../components/paragraph"
import Heading from "../../../components/heading"
import HyperCardMini from "../../../components/hyper-card-mini"
import Image from "../../../components/image"
import Layout from "../../../layouts/default"
import PageTitle from "../../../components/page-title"
import Poem from "../../../components/poem"
import Seo from "../../../components/seo"
import SectionSpacing from "../../../components/section-spacing"
import Stack from "../../../components/stack"
import StepsBox from "../../../components/steps-box"
import StepsHeading from "../../../components/steps-heading"
import Text from "../../../components/text"
import TextLink from "../../../components/textlink"
import VideoPlayer from "../../../components/video-player"

const WorkshopKalenderPage = () => {
  const data = useStaticQuery(graphql`
    query WorkshopJanuarPageQuery {
      poster01: file(
        relativePath: { eq: "workshops/kalender/januar/poster-01.jpg" }
      ) {
        ...largeImage
      }
      poster02: file(
        relativePath: { eq: "workshops/kalender/januar/poster-02.jpg" }
      ) {
        ...largeImage
      }
      poster03: file(
        relativePath: { eq: "workshops/kalender/januar/poster-03.jpg" }
      ) {
        ...largeImage
      }
      poster04: file(
        relativePath: { eq: "workshops/kalender/januar/poster-04.jpg" }
      ) {
        ...largeImage
      }
      beispiel: file(
        relativePath: { eq: "workshops/kalender/januar/beispiel.jpg" }
      ) {
        ...largeImage
      }
      postkarte: file(relativePath: { eq: "postkarte-winter.pdf" }) {
        id
        publicURL
      }
      kurse: file(relativePath: { eq: "startseite/kurse.jpg" }) {
        ...largeImage
      }
      ogImage: file(relativePath: { eq: "og_images/kalender.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 1200, height: 600, layout: FIXED)
        }
      }
    }
  `)

  return (
    <Layout backdrop="workshop-kalender-januar">
      <Seo
        title="Januar"
        description="Brrrr! Das neue Jahr hat begonnen und der kalte Monat Januar hält uns alle im warmen Zuhause. Der Dichter Friedrich Hölderlin war von der weißen Winterlandschaft um ihn herum so fasziniert, dass er ein Gedicht darüber geschrieben hat. Du kannst es mit einem schönen Wachsmalfarb-Bild verzieren!"
        image={data.ogImage.childImageSharp.gatsbyImageData}
      />
      <Stack>
        <Breadcrumb
          items={[
            {
              title: "Digital",
              link: "/digital",
            },
            {
              title: "Workshops",
              link: "/workshops",
            },
            {
              title: "Wörter und Bilder",
              link: "/workshops/kalender",
            },
            {
              title: "Januar",
              link: "/workshops/kalender/januar",
            },
          ]}
        />
        <SectionSpacing>
          <Stack>
            <PageTitle>Januar</PageTitle>
            <Paragraph dropcap={true}>
              Brrrr! Das neue Jahr hat begonnen und der kalte Monat Januar hält
              uns alle im warmen Zuhause. Der Dichter Friedrich Hölderlin war
              von der weißen Winterlandschaft um ihn herum so fasziniert, dass
              er ein Gedicht darüber geschrieben hat. Du kannst es mit einem
              schönen Wachsmal-Bild verzieren!
            </Paragraph>

            <VideoPlayer
              src="workshops/kalender/januar/01-intro"
              poster={data.poster01}
            />
          </Stack>

          <Stack>
            <StepsHeading as="h2" subtitle="Schritt 1">
              Unsere Idee für deine Kalenderseite
            </StepsHeading>
            <Paragraph>
              In seinen letzten 36 Lebensjahren, die Hölderlin in Tübingen
              verbrachte, hat er immer wieder über die Jahreszeiten geschrieben.
              Aus den 5 Fenstern seines Turmzimmers konnte er ganz genau
              mitverfolgen, wie sich die Natur Tag für Tag und Monat für Monat
              verändert. Dazu hat er eine ganze Reihe von Gedichten über den
              Frühling, Sommer, Herbst und Winter verfasst. Einige dieser
              Gedichte wirst du bei der Gestaltung deines Kalenders noch
              kennenlernen. Und mit einem davon fangen wir hier gleich einmal an
              und haben uns dazu etwas Passendes für deine 1. Kalenderseite
              ausgedacht...
            </Paragraph>
            <VideoPlayer
              src="workshops/kalender/januar/02-intro"
              poster={data.poster02}
            />
          </Stack>
          <Stack>
            <StepsHeading as="h2" subtitle="Schritt 2">
              Du brauchst....
            </StepsHeading>
            <VideoPlayer
              src="workshops/kalender/januar/03-materialien"
              poster={data.poster03}
            />
            <Constrain>
              <Stack space={6}>
                <Heading as="p" level={5}>
                  Deine Checkliste
                </Heading>
                <Stack space={4}>
                  <Checkbox space={3} id="checklist-1">
                    <Text as="span" size={4}>
                      Eine Unterlage
                    </Text>
                  </Checkbox>
                  <Checkbox space={3} id="checklist-2">
                    <Text as="span" size={4}>
                      Bunte Wachsmalstifte und einen schwarzen Wachsmalstift
                    </Text>
                  </Checkbox>
                  <Checkbox space={3} id="checklist-3">
                    <Text as="span" size={4}>
                      Einen Wachsmalkratzer, einen Zahnstocher oder etwas
                      anderes zum Kratzen
                    </Text>
                  </Checkbox>
                  <Checkbox space={3} id="checklist-4">
                    <Text as="span" size={4}>
                      Ein weißes, etwas dickeres Papier
                    </Text>
                  </Checkbox>
                  <Checkbox space={3} id="checklist-5">
                    <Text as="span" size={4}>
                      Eine Schere und einen Klebestift
                    </Text>
                  </Checkbox>
                  <Checkbox space={3} id="checklist-6">
                    <Text as="span" size={4}>
                      Hölderlins Wintergedicht
                    </Text>
                  </Checkbox>
                </Stack>
              </Stack>
            </Constrain>
          </Stack>
          <Stack>
            <StepsHeading as="h2" subtitle="Schritt 3">
              So gestaltest du ein Wachsmalkratz-Bild...
            </StepsHeading>
            <VideoPlayer
              src="workshops/kalender/januar/04-malen-und-kratzen"
              poster={data.poster04}
            />

            <Constrain>
              <Stack space={6}>
                <Heading as="p" level={5}>
                  Deine Aufgabe Schritt für Schritt:
                </Heading>
                <Stack space={6}>
                  <Stack space={3}>
                    <StepsBox step={1}>
                      <Paragraph>
                        Lies zunächst Hölderlins Wintergedicht:
                      </Paragraph>
                    </StepsBox>

                    <Box
                      sx={{
                        borderWidth: 4,
                        borderStyle: "solid",
                        borderColor: "border",
                        p: [6, 6, 8],
                      }}
                    >
                      <Stack space={6}>
                        <Heading as="p" level={5}>
                          Der Winter
                        </Heading>
                        <Poem size={[3, 3, 4]}>
                          <p>Das Feld ist kahl, auf ferner Höhe glänzet</p>
                          <p>Der blaue Himmel nur, und wie die Pfade gehen,</p>
                          <p>Erscheinet die Natur, als Einerlei, das Wehen</p>
                          <p>
                            Ist frisch, und die Natur von Helle nur umkränzet.
                          </p>
                          <br />
                          <p>Der Erde Stund ist sichtbar von dem Himmel</p>
                          <p>Den ganzen Tag, in heller Nacht umgeben,</p>
                          <p>Wenn hoch erscheint von Sternen das Gewimmel,</p>
                          <p>Und geistiger das weit gedehnte Leben.</p>
                        </Poem>
                      </Stack>
                    </Box>
                  </Stack>
                  <StepsBox step={2}>
                    <Paragraph>
                      Nimm ein weißes Papier. Achte bei der Größe darauf, dass
                      du es später in deinen Kalender einkleben kannst.
                    </Paragraph>
                  </StepsBox>
                  <StepsBox step={3}>
                    <Paragraph>
                      Male das Papier zunächst mit verschiedenfarbigen
                      Wachsmalstiften ganz bunt an.
                    </Paragraph>
                  </StepsBox>
                  <StepsBox step={4}>
                    <Paragraph>
                      Dann deckst du den bunten Hintergrund mit dem schwarzen
                      Wachsmalstift ab.
                    </Paragraph>
                  </StepsBox>
                  <StepsBox step={5}>
                    <Paragraph>
                      Schließlich kratzt du auf der schwarzen Oberfläche eine
                      Zeichnung zu Hölderlins Wintergedicht heraus.
                    </Paragraph>
                  </StepsBox>
                  <StepsBox step={6}>
                    <Paragraph>
                      Das Ergebnis kannst du zusammen mit der{" "}
                      <TextLink href={data.postkarte.publicURL}>
                        Postkarte von Hölderlins Gedicht
                      </TextLink>{" "}
                      auf deine Kalenderseite für den Monat Januar kleben.
                    </Paragraph>
                  </StepsBox>
                </Stack>
              </Stack>
            </Constrain>
          </Stack>
          <Image
            image={data.beispiel.childImageSharp.gatsbyImageData}
            alt="Auf einem Kalenderblatt mit dem Titel Januar ist eine gemalte Schneeflocke neben einer blauen Postkarte mit Hölderlins Gedicht der Winter aufgeklebt"
          />
          <Constrain>
            <Stack>
              <Paragraph>
                Wir freuen uns über ein Bild von deiner Kalenderseite! Lass dir
                von einem Erwachsenen helfen und schicke uns ein Foto an{" "}
                <TextLink href="mailto:hoelderlinturm@tuebingen.de">
                  hoelderlinturm@tuebingen.de
                </TextLink>
              </Paragraph>
              <HyperCardMini
                image={data.kurse}
                description={`Du willst mehr über Hölderlins Jahreszeitengedichte erfahren? Dann schau doch mal in unseren passenden Online-Kurs dazu.`}
                to="/kurse/jahreszeiten/"
              />
            </Stack>
          </Constrain>
        </SectionSpacing>
      </Stack>
    </Layout>
  )
}

export default WorkshopKalenderPage
